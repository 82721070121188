<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 mb-3 text-center font19" >
      Select Payment Method
    </div>
    <div class="col-12 mb-3 text-center font12 bold">
      <div class="row mx-0 justify-content-center">
        <div class="col-10 col-md-6 col-xl-5" >
          Please select the payment method you would like to use for this donation:
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 mb-3 text-center">
      <div class="row mx-0 justify-content-center">
        <div class="col-md-6 mb-3">
          <card icon="icon" title="debit order" heading="Bank" @click="selectPaymentMethod('DEBIT_ORDER')" class="cursor" :selected="paymentMethodValue === 'DEBIT_ORDER'">
            <IconBank color="gold" size="size28" />
          </card>
        </div>
        <div class="col-md-6 mb-3">
          <card icon="icon" title="online setup" heading="Card" @click="selectPaymentMethod('CREDIT_CARD')" class="cursor" :selected="paymentMethodValue === 'CREDIT_CARD'">
            <IconCalendarFull color="gold" size="size28" />
          </card>
        </div>
      </div>
    </div>
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="loggedIn"/>
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  name: 'Daily Sadaqah Select Payment Method',
  props: ['paymentMethod', 'paymentFrequencyDetails', 'user', 'activeComponent', 'theSteps'],
  emits: ['update:paymentMethod', 'update:paymentFrequencyDetails', 'update:activeComponent'],
  components: {
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    Card: defineAsyncComponent(() => import('@/views/CardSelect.vue')),
    IconBank: defineAsyncComponent(() => import('@/components/icons/IconBank.vue')),
    IconCalendarFull: defineAsyncComponent(() => import('@/components/icons/IconCalendarFull.vue'))
  },
  data () {
    return {
      isLogin: false
    }
  },
  computed: {
    paymentMethodValue: {
      get () {
        return this.paymentMethod
      },
      set (val) {
        this.$emit('update:paymentMethod', val)
      }
    },
    paymentFrequencyDetailsValue: {
      get () {
        return this.paymentFrequencyDetails
      },
      set (val) {
        this.$emit('update:paymentFrequencyDetails', val)
      }
    },
    activeComponentValue: {
      get () {
        return this.activeComponent
      },
      set (val) {
        this.$emit('update:activeComponent', val)
      }
    }
  },
  methods: {
    async selectPaymentMethod (val) {
      if (val === 'CREDIT_CARD' && !this.user) {
        this.isLogin = true
      } else {
        this.paymentMethodValue = val
        await this.setFrequency(val)
        setTimeout(() => {
          this.goNext()
        }, 500)
      }
    },
    async setFrequency (val) {
      if (val && val === 'CREDIT_CARD') {
        const startDate = await DateTime.now().plus({ days: 1 }).startOf('day').toMillis()
        this.paymentFrequencyDetailsValue.startDate = startDate
        if (startDate) {
          const theDay = await DateTime.fromMillis(startDate).toFormat('d')
          const debitOrderDay = Number(theDay)
          this.paymentFrequencyDetailsValue.debitOrderDay = debitOrderDay
          this.paymentFrequencyDetailsValue.frequency = 'MONTHLY'
        }
      } else if (val && val === 'DEBIT_ORDER' && this.paymentFrequencyDetailsValue.frequency !== 'MONTHLY') {
        this.paymentFrequencyDetailsValue.startDate = 0
        this.paymentFrequencyDetailsValue.debitOrderDay = 0
        this.paymentFrequencyDetailsValue.frequency = 'MONTHLY'
      }
    },
    loggedIn () {
      this.selectPaymentMethod('CREDIT_CARD')
    },
    goNext () {
      const index = this.theSteps.findIndex(x => x === this.activeComponentValue)
      this.activeComponentValue = this.theSteps[index + 1]
    }
  }
}
</script>
